import { Carousel } from "react-bootstrap";
import image1 from "../images/ca1.jpg";
import image2 from "../images/ca2.jpg";
import image3 from "../images/ca3.jpg";
import image4 from "../images/ca5.jpg";
import "./carousal.css"; 
const customImgStyle = {
  height: '700px',
  objectFit: 'cover',
  filter: 'brightness(85%)'
};

const captionStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '10px',
  padding: '10px'
};

const CarouselComponent = () => {
  return (
    <Carousel id="carouselExample">
      <Carousel.Item>
        <img className="d-block w-100" src={image1} alt="First slide" style={customImgStyle} />
        <Carousel.Caption style={captionStyle}>
          <h3>Vie Art</h3>
          <p>If you are looking for traditional art, Vie Art is the best option.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={image2} alt="Second slide" style={customImgStyle} />
        <Carousel.Caption style={captionStyle}>
          <h3>Vie Art</h3>
          <p>If you are looking for traditional art, Vie Art is the best option.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={image3} alt="Third slide" style={customImgStyle} />
        <Carousel.Caption style={captionStyle}>
          <h3>Vie Art</h3>
          <p>If you are looking for traditional art, Vie Art is the best option.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={image4} alt="Fourth slide" style={customImgStyle} />
        <Carousel.Caption style={captionStyle}>
          <h3>Vie Art</h3>
          <p>If you are looking for traditional art, Vie Art is the best option.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
