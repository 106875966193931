import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import a1 from "../images/a1.jpg";
import a2 from "../images/a2.jpg";
import a3 from "../images/a3.jpg";
import a4 from "../images/a4.jpg";
import './imgArtist.css'; // Import the custom CSS

const customImageStyle = {
  width: '100%',
  height: '300px',
  objectFit: 'cover'
};

function ImageArtists() {
  return (
    <div>
      <h1 className="heading">Our Artists</h1>
      <Container>
        <Row>
          <Col xs={12} md={3} className="text-center mb-4">
            <div className="image-container">
              <Image src={a1} style={customImageStyle} thumbnail />
            </div>
            <div className="artist-name">Johny Dang</div>
          </Col>
          <Col xs={12} md={3} className="text-center mb-4">
            <div className="image-container">
              <Image src={a2} style={customImageStyle} thumbnail />
            </div>
            <div className="artist-name">Kenvin Flow</div>
          </Col>
          <Col xs={12} md={3} className="text-center mb-4">
            <div className="image-container">
              <Image src={a3} style={customImageStyle} thumbnail />
            </div>
            <div className="artist-name">Mendy Dew</div>
          </Col>
          <Col xs={12} md={3} className="text-center mb-4">
            <div className="image-container">
              <Image src={a4} style={customImageStyle} thumbnail />
            </div>
            <div className="artist-name">John Wick</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ImageArtists;
