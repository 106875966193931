import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const NavbarComponent = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="p-3 shadow-sm">
      <Navbar.Brand href="#" className="font-weight-bold" style={{ marginLeft: "10px", fontSize: "1.5rem" }}>Vie Art</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="me-auto">
          <Nav.Link href="#" active className="mx-2">
            Home
          </Nav.Link>
          <Nav.Link href="#" className="mx-2">
            About us
          </Nav.Link>
          <Nav.Link href="#" className="mx-2">
            Contact
          </Nav.Link>
        </Nav>
        <Form className="d-flex" role="search" style={{ marginRight: "10px" }}>
          <FormControl 
            type="search" 
            placeholder="Search" 
            aria-label="Search" 
            className="me-2" 
            style={{ borderRadius: "20px" }}
          />
          <Button variant="outline-light" type="submit" className="rounded-pill">
            Search
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
